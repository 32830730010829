<template>
    <div class="warp">
        <!-- 返回按钮 -->
        <div class="back" @click="$router.go(-1)">
            <svg-icon icon-class="back"></svg-icon>
        </div>
        <div class="my-swipe-box">
            <v-touch @swipeleft="swipeLeft" @swiperight="swipeRight" class="touch">
                <swiper ref="swiper" :options="swiperOption" class="my-swipe">
                    <swiper-slide class="swiperSlide" v-for="(item,index) in imgs" :key="index">
                        <ImgDecypt class="tag-img" :src="item" fill="cover"></ImgDecypt>
                    </swiper-slide>
                </swiper>
            </v-touch>
            <div class="my_swiper_pagination">{{ this.current + 1 + "/" + this.imgs.length }}</div>

        </div>
        <van-popup v-model="isGoldbuyShow" class="buyPop">
            <div class="buyPopBox">
                <div class="buyTitle">本图集需要购买解锁</div>
                <div class="introduce">该内容由UP主【<span class="name">{{ postInfo.author.nickName || "未知" }}</span>】上传，并设置价格为：</div>
                <div class="amountBox">
                    <div class="moneyIcon"></div>
                    <div class="money">{{isVip ? postInfo.vipPrice : postInfo.price}}</div>
                </div>
                <div class="unlockBtn" @click="payVideo">确认解锁</div>
                <div class="tipBox">
                    <div class="tip">* 作者原创不易，会持续上传更多优秀作品</div>
                    <div class="tip">* 朋友都可上传，分享你的幸福生活</div>
                </div>
            </div>
        </van-popup>
        <!--金币购买弹窗-->
        <!-- <GoldBuy class="buy_parent" :video-info="postInfo" :show="isGoldbuyShow" @close="isGoldbuyShow=false"
            :isVip="isVip" @succcess="succcess" title="本图集需要购买解锁" /> -->
    </div>
</template>
<script>
    import ImgDecypt from "@/components/ImgDecypt"
    import {
        Swipe,
        SwipeItem
    } from 'vant';
    // import GoldBuy from "@/components/GoldBuy";
    import {
        mapGetters
    } from "vuex";
    import "swiper/dist/css/swiper.css";
    import {
        swiper,
        swiperSlide
    } from "vue-awesome-swiper";
    import { payVideo } from "@/api/app.js";
    export default {
        components: {
            ImgDecypt,
            swiper,
            swiperSlide,
            [Swipe.name]: Swipe,
            [SwipeItem.name]: SwipeItem,
            // GoldBuy
        },
        name: "PicturePreview",
        data() {
            return {
                // text: require("@/assets/png/ad.png"),
                current: 0,
                imgs: [],
                isGoldbuyShow: false,
                scale: 1,
                currentUid: "",
                // 轮播图配置
                swiperOption: {
                    loop: false,
                    allowTouchMove: false,
                    zoom: {
                        containerClass: 'swiper-zoom-container',
                        passiveListeners: false
                    },
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                    },
                    observer: true, //修改swiper自己或子元素时，自动初始化swiper
                    observeParents: true, //修改swiper的父元素时，自动初始化swiper
                    grabCursor: true,
                    onlyExternal: true,
                    autoplay: false,
                    on: {
                        slideChangeTransitionStart: () => {
                            this.current = this.swiper.activeIndex;
                        },
                        zoomChange: (scale) => {
                            this.scale = scale;
                        }
                    },

                },
                postInfo: {}
            };
        },
        computed: {
            ...mapGetters({
                isVip: 'isVip',
                // walletInfo: "walletInfo",
            }),
            swiper() {
                return this.$refs.swiper.swiper
            }
        },
        async created() {
            let dataStr = this.$route.query.data;
            let decodeData = window.atob(dataStr);
            let data = JSON.parse(decodeURI(window.decodeURI(decodeData)));
            this.imgs = data.imgs;
            if (data.index == this.imgs.length -1) {
                this.current = this.imgs.length - 2;
            } else {
                this.current = data.index || 0;
            }
            
            this.$nextTick(() => {
                this.swiper.slideTo(this.current, true);
            })
            this.postInfo = data.info;
            this.currentUid = data.uid;
            // console.log(data)
            // this.$store.dispatch("user/getWallet");
        },
        activated() {
            // this.$store.dispatch("user/getWallet");
        },
        methods: {
            swipeLeft() {
                if (this.scale != 1) return;
                let price = this.isVip ? this.postInfo?.vipPrice : this.postInfo?.price;
                let uInfo = this.$store.getters.userInfo;
                // let currentUid = this.$route.params.uid;
                if (this.current > 4 && !this.isBuy() && price > 0 && uInfo.uid != this.currentUid) {
                    this.isGoldbuyShow = true
                    return
                }
                this.swiper.slideNext();
            },
            succcess() {
                this.postInfo.vidStatus.hasPaid = true;
                this.isGoldbuyShow = false
            },
            isBuy() {
                return this.postInfo?.isBuy || false
            },
            swipeRight() {
                if (this.scale != 1) return;
                this.swiper.slidePrev();
            },
            async payVideo() {
                //点击支付视频按钮
                let req = {
                    mediaId: this.postInfo.id
                };
                this.loading = true;
                let ret = await this.$Api(payVideo, req);
                this.loading = false;
                if (ret.code == 200) {
                    this.$toast("解锁成功");
                    this.postInfo.isBuy = true;
                    this.isGoldbuyShow = false;
                    this.swiper.slideNext();
                } else if (ret.code == 6010) {
                    this.$toast(ret.tip || "余额不足");
                    let vipGrap = {
                        type: "video",
                        vInfo: this.postInfo,
                    }
                    sessionStorage.setItem('vipGrap', JSON.stringify(vipGrap));
                    this.$router.push("/wallet"); //用户余额不足直接跳金币钱包
                } else {
                    this.$toast(ret.tip || "解锁失败");
                }
            },
            // swiperdown() {
            //     if (this.scale != 1) return;
            //     this.$router.go(-1)

            // }
        },
    };
</script>
<style scoped lang="scss">
    .warp {
        height: 100%;
        position: relative;

        .touch {
            height: 100%;
        }

        .back {
            position: absolute;
            top: 12px;
            left: 20px;
            z-index: 20;

            >svg {
                font-size: 24px;
            }
        }

        .my-swipe-box {
            height: 100%;
        }

        .my_swiper_pagination {
            position: fixed;
            bottom: 20px;
            right: 20px;
            z-index: 111;
            color: #FFFFFF;
            padding: 2px 10px;
            border-radius: 6px;
            background: rgba(0, 0, 0, 0.5);
        }

        .my-swipe {
            height: 100%;
            color: #fff;
            font-size: 0.4rem;
            text-align: center;
            background: #000000;
            position: relative;

            /deep/ .vanImage {
                display: flex;
                align-items: center;
                background: #000000 !important;
            }

            /deep/ .img-decypt {
                background: #000000 !important;
            }

            /deep/ .van-image__error {
                background: #000000 !important;
            }

            /deep/ img {
                height: auto !important;
                -webkit-touch-callout: default;
            }
        }

    }


    .tag-img {
        width: 100%;
        height: 100%;
    }
    .buyPop {
        border-radius: 16px;
    }

    .buyPopBox {
        width: 300px;
        border-radius: 16px;
        .buyTitle {
            // width: 100%;
            height: 50px;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #d8d8d8;
        }
        .introduce {
            font-size: 10px;
            margin-top: 12px;
            padding: 0 10px;
            box-sizing: border-box;
            // text-align: center;
            span {
                color: #ef2851;
            }
        }

        .amountBox {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 19px 0 12px;
            .moneyIcon {
                width: 28px;
                height: 28px;
                background: url("../../assets/png/money_icon.png") center center no-repeat;
                background-size: 100%;
                margin-right: 12px;
            }
            .money {
                font-size: 46px;
            }
        }

        .unlockBtn {
            width: 186px;
            height: 30px;
            margin: 0 auto 16px;
            text-align: center;
            line-height: 30px;
            border-radius: 30px;
            font-size: 12px;
            color: #fff;
            background-image: linear-gradient(to right, #ef5080, #f26f3b);
        }
        .tipBox {
            border-top: 1px solid #d8d8d8;
            padding: 13px 37px;
            box-sizing: border-box;
            .tip {
                font-size: 10px;
                color: #999999;
            }
            .tip:nth-child(1) {
                
                margin-bottom: 4px;
            }
        }
    }
</style>